.fancybox__slide
	@apply px-5
.popup-form-contact
	@apply w-[95vw] xl:rem:max-w-[1440px] xl:w-[100%] p-4 rem:rounded-[24px]  relative z-2
	.popup-desc
		@apply text-neutral-700
	.carousel__button.is-close
		top: rr(15px)
		right: rr(15px)
		@media (max-width: 767.98px)
			top: 0
			right: 0
			transform: translate(25%, -30%)
			@apply bg-primary-500 #{!important}
		path
			@apply stroke-white md:stroke-black rem:stroke-[1px]
	&::before
		content: ''
		background: linear-gradient(to bottom, #00AEEFCC, #00648933)
		@apply absolute top-0 left-0 w-full h-full bg-black/50 -z-1 rem:rounded-[24px]
.popup-form-wrapper
	@apply bg-white rem:rounded-[12px] overflow-hidden md:p-10 p-5
	.popup-image
		@apply hidden md:block
		.img
			@apply md:min-h-0 lg:rem:min-h-[477px] h-full
			img
				@apply w-full h-full object-cover
	@media (max-width: 767.98px)
		.g-recaptcha
			@apply flex justify-end w-full
	.frm-submit
		@apply items-center
		// @apply col-span-2
	.popup-form
		@apply md:grid md:grid-cols-2 gap-6 flex flex-col
		.form-group
			.form-label
				@apply hidden
			input[type="text"], input[type="tel"], input[type="email"]
				background-image: url(../img/icon/user.svg)
				background-size: 0.9375rem 1.0416666667rem
				background-position: center right 0.38125rem
				background-repeat: no-repeat
				@apply py-4 px-2 border-b border-neutral-200 text-lg font-medium w-full outline-none ring-0 rounded-none
				@apply hocus:border-primary-500 transition-all
			&.f-phone
				input
					background-image: url(../img/icon/phone.svg)
			&.f-email
				@apply col-span-2
				input
					background-image: url(../img/icon/envelope.svg)
		.form-group.f-checkbox
			@apply col-span-2
		.multi-check-list-wrapper
			@apply flex items-center gap-2 flex-wrap
			.form-check
				@apply flex-1 flex items-center gap-3
				.form-check-label
					@apply flex items-center gap-3 cursor-pointer whitespace-nowrap text-neutral-700
					&::before
						content: '\f0c8'
						@apply text-xl font-light text-[#BDBDBD] font-awesome
				input
					@apply hidden
					&:checked ~ span::before
						content: '\f14a'
						@apply font-black text-primary-500
		.frm-submit
			@apply justify-end
.form-group
	.form-label
		@apply hidden
