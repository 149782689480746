.section-about-3
	.col-slider-year
		&::before
			content: ''
			@apply w-[200vw] h-full bg-primaryCustom-1 absolute left-1/2 top-0 -translate-x-1/2 -z-1
	.wrapper-slider-year
		&::before
			content: ''
			@apply absolute rem:top-[6px] left-0 w-full h-[1px] bg-[#BDBDBD]
		.process-bar-year
			@apply absolute rem:top-[6px] left-0 h-[1px] bg-primary-500 w-[var(--process)]
			@apply transition-all duration-300 ease-linear
		.swiper-slide
			@apply relative
			.circle-year
				@apply transition-all duration-300
			&.swiper-slide-thumb-active
				.item-year
					.circle-year
						@apply relative
						@apply bg-primary-500
					.text-year
						@apply text-primary-500
