.expand-content-text
	&.show
		&>*
			@apply block #{!important}
	&>p:first-child
		@apply block
	&>*
		@apply hidden
.expand-content-item
	&.show
		&>[class*="item-utility"]
			&:nth-child(n+10)
				@apply flex #{!important}
	&>[class*="item-utility"]
		&:nth-child(n+10)
			@apply hidden
.expand-content-table
	&.show
		table
			tbody
				tr
					&:nth-child(n+10)
						@apply block #{!important}
	table
		tbody
			tr
				&:nth-child(n+10)
					@apply hidden

