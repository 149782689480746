.title-40px
	@apply text-[26px] lg:text-40 font-bold
.title-48px
	@apply text-[32px] md:text-5xl lg:text-6xl font-bold leading-tight
.title-32px
	@apply text-[24px] md:text-32 font-bold
.title-24px
	@apply text-[18px] md:text-2xl font-bold
.title-20px
	@apply text-[16px] md:text-xl font-bold
