.section-house-consignment-7
	.container
		@media (max-width: 767.98px)
			@apply px-0
	.col-content
		&::before
			content: ''
			background-image: url(../img/popup.png)
			@apply absolute left-0 top-0 w-full md:w-[120%] lg:w-[50vw] h-full -z-1 pointer-events-none
		.wrap-form
			.popup-form
				@apply gap-3
				.form-group
					&.f-email
						input
							background-image: url(../img/icon/envelope-white.svg) !important
					&.f-phone
						input
							background-image: url(../img/icon/phone-white.svg) !important
					input
						background-image: url(../img/icon/user-white.svg) !important
						@apply rem:min-h-[48px] py-2 bg-transparent text-white placeholder:text-white text-base #{!important}
						@apply hocus:border-primary-300
				textarea
					@apply outline-none ring-0 px-2
					@apply hocus:border-primary-300 #{!important}
					@apply rem:min-h-[120px] py-2 bg-transparent text-white placeholder:text-white text-base border-b border-white w-full #{!important}
