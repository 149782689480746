.section-home-property-selling
	.wrap-item-product
		@apply relative
		.item-product-image-full
			@apply absolute top-0 left-0 w-full h-full bg-transparent object-cover transition-all duration-300 opacity-0
		&.hover
			.item-product-image-full
				@apply opacity-100
			.item-product-secondary
				img
					@apply opacity-0
