section.section-news-detail
	.box-comment
		.wrap-form-comment
			textarea,input:not([type="hidden"], [type="submit"])
				@apply w-full rem:h-[48px] border border-neutral-200 rounded-2 px-6 py-5
				@apply text-lg font-medium outline-none hocus:border-primary-500 transition-all duration-300
			textarea
				@apply rem:h-[145px]
	.wrap-toc
		&.open
			.title-toc
				i.fa-solid.fa-chevron-down
					@apply rotate-180
	.format-content
		img
			@apply max-w-full
	.js-toc
		ol
			counter-reset: item
			li
				a
					&::before
						content: counters(item, ".") " ."
						counter-increment: item
		&>ol
			&>li
				@apply text-base font-bold text-primary-500
				&>a
					@apply mb-2 block
				ol
					@apply pl-5
					li
						@apply text-sm text-primaryCustom-1 font-light
						a
							@apply hover:bg-primary-200 rounded-2 transition-all duration-300 block w-full px-2 py-1 cursor-pointer
							&.is-active-link
								@apply bg-primary-200
.fob-comment-form
	.row-cols-lg-2
		@apply flex flex-col mx-0 #{!important}
		.form-label
			@apply hidden
