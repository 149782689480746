footer
	.footer-list
		@apply flex flex-col gap-3
		li
			@apply flex items-center gap-2
	.footer-title
		@apply text-lg font-bold
	.footer-list-address
		@apply flex flex-col gap-3
		li
			@apply flex gap-2
	.footer-list-links
		ul
			@apply flex flex-col gap-3
			li
			a
				@apply hover:text-primary-500
	.footer-list-social
		@apply flex items-center gap-8
		li
			a
				@apply text-xl text-white/50 transition-all duration-300 hover:text-primary-500
	.col
		@media (max-width: 1023.98px)
			&:last-child
				.col-footer-links
					@apply border-b border-neutral-700
	.col-footer-links
		@media (max-width: 767.98px)
			@apply border-t border-neutral-700
			&.open
				.footer-title
					.icon-dropdown
						@apply rotate-180
			.footer-title
				@apply flex items-center justify-between mb-0 py-4 text-[18px]
				.icon-dropdown
					@apply transition-all duration-300
					&::before
						content: '\f078'
						@apply font-awesome text-white text-[18px] font-normal
			.footer-list-links
				@apply hidden pb-5
.tool-cta
	@apply z-[999]
	.button-to-top
		@apply hidden
		&.active
			@apply block
	.btn-cta
		@apply px-3
		img
			@apply max-w-full
