.global-breadcrumb
	@apply border-b border-neutral-200 overflow-auto
	ul
		@apply flex items-center h-12.5
		li
			&+li
				&::before
					content: '\f105'
					@apply font-awesome font-light text-neutral-400 mx-4
			&:first-child
				a
					@apply text-0 leading-[1] #{!important}
					&::before
						content: '\f015'
						@apply font-awesome text-xl font-light text-neutral-400
			a,span
				@apply text-lg font-medium text-neutral-400
