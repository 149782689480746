.section-contact-form
	.form-white-transparent
		@apply gap-3
		.form-group
			&.f-email
				input
					background-image: url(../img/icon/envelope-white.svg) !important
			&.f-phone
				input
					background-image: url(../img/icon/phone-white.svg) !important
			input
				background-image: url(../img/icon/user-white.svg) !important
				@apply rem:min-h-[66px] py-2 bg-transparent text-white placeholder:text-white text-lg font-medium #{!important}
				@apply hocus:border-primary-600 #{!important}
		textarea
			@apply outline-none ring-0 px-2
			@apply hocus:border-primary-600 #{!important}
			@apply rem:min-h-[120px] py-2 bg-transparent text-white placeholder:text-white text-base border-b border-white w-full #{!important}
