@layer utilities
	.section-py
		@apply py-10 lg:py-15 xl:py-20
	.no-gutters
		@apply mx-0
		> *
			@apply px-0
	.lozad
		opacity: 0
		filter: blur(10px)
		transition: .3s all ease-in-out
		&[data-loaded="true"]
			opacity: 1
			filter: blur(0)
	.scroll-mandatory
		scroll-snap-type: x mandatory
		&>*
			scroll-snap-align: start
	.desktop-hidden
		@apply flex md:hidden #{!important}
	.mobile-hidden
		@apply hidden md:flex #{!important}
	.tabs-center
		li
			@apply first:ml-auto last:mr-auto
	.item-btn-video
		&:hover
			.btn-video
				// box-shadow: 0 0 0 11.5px rgb(0 144 212 / 25%), 0 0 0 20px rgb(0 144 212 / 20%)
	.scroll-style-primary
		+scroll()
	.img-cover
		img
			@apply w-full h-full object-cover #{!important}
