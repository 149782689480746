.btn
	@apply cursor-pointer select-none transition-all duration-300
	&.btn-solid
		@apply rem:rounded-[8px] h-[40px] lg:rem:h-[42px] px-4 text-lg font-bold border border-neutral-50 flex items-center
		@apply transition-all duration-300 hover:bg-primary-500 hover:border-primary-500
	&.btn-sw-1
		svg
			@apply transition-all duration-300 lg:w-6 w-5
			path
				@apply transition-all duration-300
		&:hover
			svg
				@apply scale-110
				path
					fill-opacity: 1
					@apply fill-white
	&.btn-primary
		@apply bg-primary-500 text-white h-[46px] md:h-12.5 px-[24px] md:px-6 font-medium uppercase rounded-2 flex-center
		@apply transition-all duration-300 gap-2
		&[class*="uppercase"]
			span
				@apply uppercase #{!important}
		&.transparent
			@apply bg-transparent border border-white hover:border-primary-600
			span
				@apply text-lg font-medium normal-case
		&:hover
			@apply bg-primary-600
			.icon
				&::before
					@apply translate-x-[125%]
				&::after
					@apply translate-x-0
		.icon
			@apply w-[14px] h-[15px] xl:rem:w-[16px] xl:rem:h-[16px] relative overflow-hidden flex-shrink-0 flex items-center text-base
			&::before
				@apply translate-x-0
			&::after
				@apply -translate-x-[125%]
			&::before,&::after
				content: '\f178'
				display: inline-block
				@apply text-white font-light rem:text-[16px]
				@apply absolute inline-block font-awesome transition-all duration-300
		&.gray
			@apply border border-neutral-500 text-neutral-500 hover:bg-primary-500 hover:text-white
			&:hover
				.icon
					&::before,&::after
						@apply text-white
			.icon
				&::before,&::after
					@apply text-neutral-500
	&.btn-sw-2
		@apply text-2xl
	&.btn-sw-3
		@apply w-10 h-10 border border-neutral-500 flex-center rounded-2
		@apply transition-all duration-300 hover:bg-primary-500 hover:text-white
		@apply hover:border-primary-500
		&.white
			@apply border-white
			&::before
				@apply text-white
		&:hover
			&::before
				@apply text-white
		&::before
			@apply font-awesome font-light text-neutral-500 transition-all duration-300
		&.btn-prev
			&::before
				content: '\f177'
		&.btn-next
			&::before
				content: '\f178'
	&.btn-view-more
		@apply text-lg font-medium text-primary-500 gap-2 flex items-center hover:text-primary-600
		&.active
			&::after
				@apply rotate-180
		&.arrow-right
			&.active
				&::after
					@apply -rotate-90
			&::after
				content: '\f105'
				@apply translate-y-1
		&::after
			content: '\f107'
			@apply font-awesome font-light transition-all duration-300
	&.btn-video
		box-shadow: 0 0 0 0px rgb(0 144 212 / 25%), 0 0 0 0px rgb(0 144 212 / 20%)
		@apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2
		@apply w-15 h-15 flex-center rounded-full text-white
		@apply transition-all duration-300 z-10
		&::after
			content: ''
			box-shadow: 0 0 0 11.5px rgb(0 144 212 / 25%), 0 0 0 20px rgb(0 144 212 / 20%)
			animation: btn-video-pulse 2s infinite 1s ease-out
			@apply absolute left-0 bottom-0 w-full h-full rounded-full -z-1
		&::before
			content: '\f04b'
			@apply font-awesome text-2xl font-black absolute w-full h-full flex-center z-2 bg-primary-500 rounded-full translate-x-0 translate-y-0 pl-1
	&.btn-double
		@apply flex
		a
			@apply text-sm font-medium flex-center rem:min-h-[34px] px-4 border border-primary-500 rem:min-w-[100px] first:rounded-l-2 last:rounded-r-2
			&.active
				@apply bg-primary-500 text-white
@keyframes btn-video-pulse
	0%
		transform: scale(.5)
	40%
		transform: scale(1)
	60%
		transform: scale(1)
	100%
		transform: scale(.5)
