.wrap-button-slide
	display: flex
	align-items: center
	justify-content: center
	&.pagination-disable
		@apply hidden xl:flex
	&.in-static
		.btn-prev,.btn-next
			@apply top-1/2 -translate-y-1/2
		.btn-prev
			@apply left-[15px] lg:left-10 right-auto
		.btn-next
			@apply right-[15px] lg:right-10 left-auto
	&.none-static
		.btn-prev,.btn-next
			position: absolute !important
			top: 50%
			transform: translateY(-50%)
			@media (max-width: 1279.98px)
				margin: 0 !important
		.btn-prev
			@media (max-width: 1023.98px)
				right: 94%
			@media (max-width: 575.98px)
				right: auto
				left: 10px
		.btn-next
			@media (max-width: 1023.98px)
				left: 94%
			@media (max-width: 575.98px)
				left: auto
				right: 10px
	.btn-prev,.btn-next
		z-index: 9
		cursor: pointer
		position: absolute
		top: 50%
		transform: translateY(-50%)
