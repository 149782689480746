.section-house-consignment-6
	.item-toggle
		&.active
			@apply  bg-white border border-primary-500
			.title
				@apply text-primary-500
				&::after
					content: '\f068'
		.title
			&::after
				content: '\2b'
				@apply text-lg font-black font-awesome
