header
	.header-menu-mobile
		@media (max-width: 1023.98px)
			@apply fixed top-[70px] bg-primaryCustom-1 w-[95vw] left-1/2 -translate-x-1/2 rounded-4 p-5
			@apply opacity-100 invisible translate-x-[100%]
			@apply shadow-md border-4 border-white/30
			+transition-base()
			.header-menu
				@apply order-2 border-t border-neutral-50/20 pt-5 w-full pb-5 border-b
			.header-action
				@apply order-1 w-full text-center justify-center mb-5
			.header-language
				@apply order-3
	.header-menu
		li
			&[class*="current"]
				a
					@apply text-primary-500
		@media (max-width: 1023.98px)
			@apply flex flex-col gap-5
			li
				a
					@apply text-3xl font-semibold hover:text-primary-500  flex items-center gap-2
		@screen lg
			@apply flex items-center gap-12
			li
				a
					@apply text-lg font-semibold hover:text-primary-500 flex items-center gap-2
	.btn-solid
		@apply font-semibold
	.header-language
		@apply relative
		&:hover
			.lang-list
				@apply opacity-100 visible
		.lang-active
			@apply px-4 rem:h-[42px] flex-center gap-2 text-lg font-bold border border-white rounded-2
			&::after
				content: '\f078'
				@apply font-awesome text-sm font-light
		.lang-list
			@apply absolute top-full left-0 w-full bg-white rem:rounded-2 text-[#333333] text-lg font-semibold translate-y-1 shadow-md overflow-hidden transition-all duration-300
			@apply opacity-0 invisible
			li
				a
					@apply block rem:py-2 rem:px-4 hover:bg-primary-500 hover:text-white transition-all duration-300
	.header-search
		&>*
			@apply pointer-events-none
body
	&.isOpenMenu
		header
			.header-menu-mobile
				@apply opacity-100 visible -translate-x-1/2
