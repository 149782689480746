.header-search-form
	@apply -translate-y-20 pointer-events-none invisible transition-all duration-300
	@apply shadow-md border-4 border-white/30
	&.active
		@apply translate-y-2 pointer-events-auto visible
	input
		@apply flex-1 bg-white bg-transparent transition-all duration-300 pr-15 placeholder:text-white/50 text-white py-3 pl-8
		@apply outline-none
	button.btn-search
		@apply text-white absolute left-0
	button.close
		@apply absolute right-0 text-white text-2xl flex mt-1
