.section-buy-detail
	.product-title-specs
		@apply whitespace-nowrap flex-wrap
	#form-contact
		@apply overflow-visible #{!important}
		.g-recaptcha
			@apply w-full max-w-full #{!important}
			& *
				@apply w-full max-w-full #{!important}
		&.active
			&::before
				@apply opacity-100
		&::before
			content: '\e1a1'
			animation: arrow-right 1s infinite ease-in-out
			@apply absolute top-1/2 -translate-y-1/2 right-full mr-5 text-primary-500 rem:text-[42px] font-awesome opacity-0 transition-opacity duration-300
	&.section-banner
		.wrap-thumb-banner
			@apply px-9 md:px-12.5 relative
			.btn-sw-2
				@apply absolute top-1/2 -translate-y-1/2
				&.btn-prev
					@apply left-0
				&.btn-next
					@apply right-0
			.swiper-slide
				.item-thumb-banner
					@apply border-[2px] border-transparent
				&.swiper-slide-thumb-active
					.item-thumb-banner
						@apply border-primary-500
		.wrap-btn-tab
			@media (max-width: 767.98px)
				@apply absolute bottom-[115px] flex-center w-full z-10 gap-2
				.btn-tab-banner
					@apply flex-center border-2 border-white rounded-full w-[35px] h-[35px] p-0 min-w-0 border-solid  #{!important}
					.icon
						@apply w-full h-full flex-center
						img
							@apply w-5 h-5 filter-white
					.text
						@apply hidden
			.btn-tab-banner
				&.active
					@apply bg-primaryCustom-1 text-white
					img
						@apply filter-white
	&.section-information
		.product-title-specs
			li
				@apply flex items-center
				@apply text-[#3B3B3B]
				i,span
					@apply text-[#3B3B3B]
				&+li
					&::before
						content: ''
						@apply w-[1px] h-[24px] bg-neutral-300 inline-block mx-2 md:mx-4
	.wrap-tools-social
		@screen lg
			@apply absolute top-10 h-full -translate-x-[calc(100%+2.0833333333rem)]
		ul
			@apply sticky top-[100px]
		li
			a
				@apply p-2
			img
				@apply max-w-full
			&:hover
				img
					@apply filter-white
	.tab-utilities-area
		.item-utility-area
			table
				@apply w-full
				tr
					td
						@apply first:text-left last:text-right last:font-bold py-2
	.wrap-form-contact
		.form-group
			@apply mb-2
			textarea
				@apply bg-transparent w-full px-4 py-3 text-white text-base border-b border-white placeholder:text-white ring-0 outline-none rem:min-h-[119px]
			input:not([type="submit"])
				background-image: url(../img/icon/user-white.svg)
				background-size: 0.9375rem 1.0416666667rem
				background-position: center right 0.38125rem
				background-repeat: no-repeat
				@apply rounded-none
				&[type="tel"]
					background-image: url(../img/icon/phone-white.svg)
				&[type="email"]
					background-image: url(../img/icon/envelope-white.svg)
				@apply bg-transparent w-full px-4 py-3 text-white text-base border-b border-white placeholder:text-white ring-0 outline-none
	.tab-project-detail
		.item-project-detail
			.img-ratio
				@apply border border-[#B0BDCB]
	.wrap-other-product
		.wrap-specs
			li
				@apply whitespace-nowrap
.popup-modal-3d
	@apply p-5 max-w-[90w] w-full
	.wrap-iframe
		@apply img-ratio pt-[100%] lg:pt-[45.5%]
@keyframes arrow-right
	0%
		transform: translateX(0)
	70%
		transform: translateX(10px)
	100%
		transform: translateX(0)
