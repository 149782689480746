@charset "UTF-8";
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}

form.invalid .wpcf7-response-output {
  @apply text-red-600 border-red-600 bg-white !important;
}

.wpcf7-response-output {
  @apply text-green-400 border-green-400 bg-white border py-1 px-4 text-center mt-2 mb-0 ml-0 mr-0 text-sm !important;
}

.form-group {
  @apply relative;
}
.form-group .wpcf7-not-valid-tip {
  @apply absolute top-1/2 -translate-y-1/2 right-3 text-xs text-red-600;
}

.frm-submit {
  @apply relative;
}
.frm-submit .wpcf7-spinner {
  @apply absolute left-full top-1/2 -translate-y-1/2 ml-2;
}

.edit-link i:before {
  content: "✏️";
}

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap-button-slide.pagination-disable {
  @apply hidden xl:flex;
}
.wrap-button-slide.in-static .btn-prev, .wrap-button-slide.in-static .btn-next {
  @apply top-1/2 -translate-y-1/2;
}
.wrap-button-slide.in-static .btn-prev {
  @apply left-[15px] lg:left-10 right-auto;
}
.wrap-button-slide.in-static .btn-next {
  @apply right-[15px] lg:right-10 left-auto;
}
.wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
    margin: 0 !important;
  }
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-static .btn-prev {
    right: 94%;
  }
}
@media (max-width: 575.98px) {
  .wrap-button-slide.none-static .btn-prev {
    right: auto;
    left: 10px;
  }
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-static .btn-next {
    left: 94%;
  }
}
@media (max-width: 575.98px) {
  .wrap-button-slide.none-static .btn-next {
    left: auto;
    right: 10px;
  }
}
.wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.zoom-img {
  overflow: hidden;
}
.zoom-img:hover img {
  transform: scale(1.05) translateZ(0);
}
.zoom-img img {
  transition: 0.5s all ease-in-out !important;
}

.ring-food {
  animation: 1.5s ring-food infinite ease-in-out alternate;
}

.ring-chili {
  animation: 1.5s ring-chili infinite ease-in-out alternate;
}

.ring-food-footer {
  animation: 1.5s ring-food-footer infinite ease-in-out alternate;
}

@keyframes ring-chili {
  0% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
@keyframes ring-food-footer {
  0% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes ring-food {
  0% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(330deg);
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
span.fa-exclamation-triangle {
  @apply text-red-500 text-sm font-primary py-2 !important;
}
span.fa-exclamation-triangle::before {
  display: none;
}

.btn.btn-default.frm-btn-reset {
  @apply hidden;
}

.frm-btnwrap, .frm-captcha {
  opacity: 0;
}

.wrap-form-submit {
  @apply flex items-center flex-wrap justify-between gap-5 md:gap-4;
}
.wrap-form-submit .frm-captcha, .wrap-form-submit .frm-btnwrap {
  opacity: 1;
}
.wrap-form-submit .frm-captcha {
  @apply flex flex-row-reverse items-center flex-1;
}
.wrap-form-submit .frm-captcha .RadCaptcha {
  width: fit-content !important;
  @apply relative;
}
.wrap-form-submit .frm-captcha .RadCaptcha > span {
  @apply absolute top-full left-0 text-red-500;
}
.wrap-form-submit .frm-captcha .RadCaptcha > div > div {
  @apply flex items-center;
}
.wrap-form-submit .frm-captcha .RadCaptcha img {
  width: 8.28125rem !important;
  height: 2.6041666667rem !important;
  @apply border border-[#EEEEEE] !important;
}
.wrap-form-submit .frm-captcha .RadCaptcha a.rcRefreshImage {
  @apply text-0 before:content-[""] before:font-awesome before:text-2xl before:mx-3 before:inline-block w-full h-full text-[#333333];
  @apply flex-center !important;
}
.wrap-form-submit .frm-captcha .frm-captcha-input {
  @apply flex-1;
}
.wrap-form-submit .frm-captcha .frm-captcha-input label {
  @apply hidden;
}
@media (max-width: 767.98px) {
  .wrap-form-submit .frm-btnwrap {
    @apply flex-[0_0_100%];
  }
}
.wrap-form-submit .frm-btnwrap label {
  @apply hidden;
}

.search-page {
  padding: 40px 0;
}
.search-page h1 {
  letter-spacing: -2px;
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  color: #333333;
}
@media (max-width: 1024px) {
  .search-page h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.search-page h1.center {
  text-align: center;
}
.search-page .btn-reindex {
  display: none;
}
.search-page .searchcontrols .form-group {
  position: static;
}
.search-page .searchcontrols .form-inline {
  position: relative;
}
.search-page .searchcontrols .form-inline:before {
  content: "";
  background-image: url("/Data/Sites/1/skins/default/img/icon/search.svg");
  @apply absolute w-6 h-6 flex-center top-1/2 right-4 brightness-0 -translate-y-1/2;
}
.search-page .searchcontrols .frm-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
.search-page input[type=text] {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 54px;
  border: 1px solid #dfe1e5;
  background-color: #fff;
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 22px;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  @apply h-12.5;
}
.search-page input[type=submit] {
  @apply absolute top-0 right-0 h-full flex-center px-4 h-12.5 text-0;
}
.search-page .text-danger {
  margin-top: 20px;
  font-size: 15px;
  color: #333;
  font-style: italic;
  font-weight: 600;
}
.search-page .searchresultsummary {
  margin-bottom: 20px;
}
.search-page .searchresults {
  margin-top: 30px;
}
.search-page .searchresults .modulepager:first-child {
  display: none;
}
.search-page .searchresultlist {
  margin-bottom: 20px;
}
.search-page .searchresult {
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 16px;
  border-radius: 8px;
}
.search-page .searchresult:last-child {
  margin-bottom: 0;
}
.search-page .searchresult h3 {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult h3 a {
  color: #1A0DAB;
  text-decoration: none;
  font-weight: 500;
}
.search-page .searchresult h3 a:hover {
  text-decoration: underline;
}
.search-page .searchresult .searchresultdesc {
  color: #545454;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult .searchterm {
  color: #6a6a6a;
  font-weight: bold;
  font-style: normal;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
}
.search-page .searchresult hr {
  display: none !important;
}

.loginpage header {
  display: none;
}
.loginpage footer {
  display: none;
}
.loginpage main {
  background-color: #F0F0F1;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  padding-top: 0 !important;
  padding: 0 15px;
}
.loginpage .header-tools-mobile {
  display: none !important;
}
.loginpage .login-box {
  max-width: 690px;
  margin: 0 auto;
  width: 100%;
}
.loginpage .login-box .login-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
  font-size: 0;
  margin-bottom: 30px;
}
.loginpage .login-box .login-logo::before {
  content: "";
  display: inline-block;
  background-image: url("../img/logo-canh-cam.png");
  width: 267px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: 267px auto;
  margin-bottom: 15px;
}
.loginpage .login-box .loginstandard {
  background-color: #fff;
  box-shadow: 0, 4px, 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0;
  padding: 40px 60px 30px;
  font-weight: 400;
  overflow: hidden;
  font-family: Roboto, sans-serif !important;
}
@media (max-width: 767.98px) {
  .loginpage .login-box .loginstandard {
    padding: 15px 20px;
  }
}
.loginpage .login-box .loginstandard .card-body .row.items-center {
  @apply flex-wrap mx-0;
}
.loginpage .login-box .loginstandard .card-body .row.items-center .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.loginpage .login-box .loginstandard .card-body .login-box-msg {
  display: none;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group .input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #d1d1d1;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input {
  background: #f5f5f5;
  border-radius: 25px;
  height: 50px;
  border: 0;
  outline: none;
  box-shadow: 0 0 0 1px #f5f5f5;
  transition: 0.3s all ease-in-out;
  padding: 0 35px;
  font-size: 14px;
  width: 100%;
  border: 1px solid transparent;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input:hover, .loginpage .login-box .loginstandard .card-body .panel-body .input-group input:focus {
  border: 1px solid #f73936;
}
.loginpage .login-box .loginstandard .card-body .panel-body .icheck-primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-bottom: 20px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .btn-primary {
  background-color: #f73936;
  border-radius: 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  border: 0;
  outline: none;
  transition: 0.3s all ease-in-out;
}
.loginpage .login-box .loginstandard .card-body .panel-body .flex.justify-between.mt-3 {
  margin-top: 30px;
  font-size: 14px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .cart-login {
  display: none;
}
.loginpage .login-box .loginstandard .card-body span.alert {
  color: #f73936;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.sitemap {
  @apply py-15;
}

.sitemap-heading h1 {
  @apply text-lg text-center uppercase mx-auto;
  @apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-[1px] before:bg-white;
}

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root a {
  @apply inline-flex items-center px-7 py-3 text-xl bg-blue-400 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-red-300;
}
.AspNet-TreeView .AspNet-TreeView-Root > a {
  @apply before:absolute before:left-full before:w-[120%] before:h-[1px] before:bg-gray-300 before:-z-1;
}
.AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
  @apply hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root li {
  @apply pl-3;
}
.AspNet-TreeView .AspNet-TreeView-Root li a {
  @apply bg-blue-400/[.9];
}
.AspNet-TreeView .AspNet-TreeView-Root li li a {
  @apply bg-blue-400/[.75];
}
.AspNet-TreeView .AspNet-TreeView-Root li li li a {
  @apply bg-blue-400/[.6];
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
  @apply before:absolute before:w-[1px] before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
  @apply before:top-full;
}
.AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
  @apply before:hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
  @apply mt-2 relative z-1;
  @apply after:absolute after:w-[1px] after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-gray-300 after:pointer-events-none;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
  @apply relative;
  @apply before:absolute before:w-3 before:-z-1 before:h-[1px] before:bg-gray-300 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none;
}

.cmsadminpanel ul {
  display: flex;
  align-items: center;
}
.cmsadminpanel.isClose .navbar-nav {
  @apply hidden;
}
.cmsadminpanel.isClose #pullmenucms i::after {
  content: "Mở";
}
.cmsadminpanel #pullmenucms i::after {
  content: "Đóng";
}

.notfound .notfound-404 h1 {
  @apply bg-red-400 bg-none !important;
}
.notfound a {
  @apply bg-red-400 bg-none shadow-none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    scroll-padding-top: 100px;
    scroll-behavior: smooth;
  }
  html, body {
    font-size: 17px;
  }
  @screen md {
    html, body {
      font-size: 17px;
    }
  }
  @screen xl {
    html, body {
      font-size: 1vw;
    }
  }
  .table-responsive {
    @apply overflow-auto;
  }
  @media (max-width: 767.98px) {
    .table-responsive table {
      width: 700px !important;
    }
  }
  .img-full img {
    @apply w-full h-full object-cover;
  }
  .img-contain img {
    @apply w-full h-full object-contain !important;
  }
  .img-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .img-ratio img, .img-ratio iframe {
    @apply absolute top-0 left-0 w-full h-full object-cover transition-all duration-300;
  }
  .img-ratio-center {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @apply flex-center;
  }
  .img-ratio-center img, .img-ratio-center iframe {
    @apply absolute top-1/2 left-1/2 w-auto h-auto object-contain transition-all duration-300 max-w-full max-h-full -translate-x-1/2 -translate-y-1/2;
  }
  .div-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .div-ratio > div {
    @apply absolute top-0 left-0 w-full h-full transition-all duration-300;
  }
  body {
    @apply text-[#333333] text-base font-primary;
  }
  .row {
    @apply flex flex-wrap -mx-[7.5px] lg:-mx-5;
  }
  .col {
    @apply px-[7.5px] lg:px-5 w-full;
  }
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  img {
    @apply inline max-w-none;
  }
  a {
    @apply transition-all ease-linear duration-200;
  }
  p {
    @apply empty:hidden;
  }
  input {
    @apply outline-offset-0 shadow-none ring-0 !important;
  }
  .section {
    @apply py-12.5 lg:py-20;
  }
  .section-40 {
    @apply lg:py-10 py-6;
  }
  .margin-p p + p {
    @apply mt-5;
  }
  .overflow-scroll-1::-webkit-scrollbar {
    @apply w-[calc(6/1920*100rem)];
  }
  .overflow-scroll-1::-webkit-scrollbar-track {
    background-color: rgba(229, 229, 229, 0.2);
  }
  .overflow-scroll-1::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }
  body.disable-scroll {
    @apply overflow-hidden;
  }
  .format-content img {
    @apply block;
  }
  .format-content p {
    @apply mb-3;
  }
  .format-content ul {
    @apply list-disc pl-5 flex flex-col gap-3 mb-3;
  }
  .format-content ul li::marker {
    @apply text-primary-500;
  }
  .overlay-black {
    @apply relative z-2;
  }
  .overlay-black::before {
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%);
    @apply absolute top-0 left-0 w-full h-full -z-1 pointer-events-none;
  }
  .mb-base {
    @apply lg:mb-10 mb-8;
  }
}
@layer components {
  .item-comp {
    @apply flex;
  }
  .pagination-style-primary .swiper-pagination-bullet {
    @apply bg-primaryCustom-1 opacity-50 transition-all duration-300;
  }
  .pagination-style-primary .swiper-pagination-bullet-active {
    @apply bg-primary-500 opacity-100;
  }
  .tabslet-tab-primary {
    @apply flex items-center overflow-auto pb-5 pt-2;
  }
  .tabslet-tab-primary.base-font-size li a {
    @apply text-base;
  }
  .tabslet-tab-primary.no-spacing {
    @apply gap-10;
  }
  .tabslet-tab-primary.no-spacing li a {
    @apply px-0;
  }
  .tabslet-tab-primary li.active a {
    @apply text-black;
  }
  .tabslet-tab-primary li.active a::before, .tabslet-tab-primary li.active a::after {
    @apply opacity-100;
  }
  .tabslet-tab-primary li a {
    @apply text-lg font-bold text-[#838383] px-5 leading-none py-3 hover:text-black relative whitespace-nowrap flex-center;
  }
  .tabslet-tab-primary li a::before {
    content: "";
    @apply w-full h-[2px] bg-primary-500 absolute top-full left-0 transition-all duration-300;
    @apply opacity-0;
  }
  .tabslet-tab-primary li a::after {
    content: "";
    @apply w-0 h-0;
    @apply border-l-[8px] border-r-[8px] border-b-[10px];
    @apply border-l-transparent border-r-transparent border-b-primary-500;
    @apply absolute top-full left-1/2 -translate-x-1/2 rotate-180;
    @apply opacity-0 transition-all duration-300;
  }
  .item-product {
    @apply flex flex-col h-full;
  }
  .item-product:hover .product-title {
    @apply text-primary-500;
  }
  .item-product:hover .product-image img {
    @apply scale-105;
  }
  .item-product .tags-list {
    @apply absolute top-2.5 right-2.5 z-2;
    @apply flex items-center gap-2;
  }
  .item-product .tags-list li {
    @apply text-sm font-medium bg-primary-500;
    @apply leading-none text-white px-2 rounded-1 rem:min-h-[28px] flex-center;
  }
  .item-product .tags-list li.sale {
    @apply bg-primary-950;
  }
  .item-product .tags-list li.hot {
    @apply bg-button-primary;
  }
  .item-product .product-title {
    @apply transition-all duration-300;
  }
  .item-product .product-image {
    @apply rem:rounded-t-[12px];
  }
  .item-product .product-content {
    @apply border border-[#BDBDBD] border-t-0 rem:rounded-b-[12px] flex-1;
  }
  .item-product.primary .product-specs {
    @apply gap-7;
  }
  .item-product.primary .product-specs i {
    @apply text-base;
  }
  .item-product.big .btn-video {
    @apply w-20 h-20;
  }
  .item-product.big .btn-video::before {
    @apply text-3xl;
  }
  .item-product.min .product-content {
    @apply p-4 gap-2;
  }
  .item-product.min .product-location, .item-product.min .product-price {
    @apply text-sm;
  }
  .item-product.min .product-location i, .item-product.min .product-price i {
    @apply text-xs;
  }
  .item-product .product-specs {
    @apply mt-auto;
  }
  .item-product .product-specs i {
    @apply text-[#7A848F];
  }
  .item-product:hover .btn-video {
    box-shadow: 0 0 0 11.5px rgba(0, 144, 212, 0.25), 0 0 0 20px rgba(0, 144, 212, 0.2);
  }
  .btn-video {
    box-shadow: 0 0 0 0px rgba(0, 144, 212, 0.25), 0 0 0 0px rgba(0, 144, 212, 0.2);
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
    @apply w-15 h-15 bg-primary-500 flex-center rounded-full text-white;
    @apply transition-all duration-300;
  }
  .btn-video::before {
    content: "\f04b";
    @apply font-awesome text-2xl font-black  rem:translate-y-[1px]  rem:translate-x-[3px];
  }
  .item-product-secondary {
    @apply relative;
  }
  .item-product-secondary::after {
    content: "";
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    @apply absolute right-0 top-0 w-[2px] h-full translate-x-[1px] z-2 pointer-events-none;
  }
  .item-product-secondary::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
    opacity: 0.5;
    @apply absolute left-0 bottom-0 w-full h-full z-1 pointer-events-none transition-all duration-300;
  }
  .item-product-secondary:hover::before {
    opacity: 1;
    @apply h-[100%];
  }
  .item-product-secondary:hover .product-info {
    @apply translate-y-0 pointer-events-auto;
  }
  .item-product-secondary:hover .btn-primary {
    @apply opacity-100;
  }
  .item-product-secondary .btn-primary {
    @apply opacity-0 transition-all duration-300;
  }
  .item-product-secondary .product-info {
    @apply translate-y-[3.6458333333rem] lg:translate-y-[4.6875rem] transition-all duration-300 pointer-events-none;
  }
  .item-product-secondary .product-image img {
    @apply transition-all duration-300;
  }
  .item-news .news-title {
    @apply text-lg font-bold py-3 border-b border-neutral-300 mb-3;
  }
  .item-news .news-title a {
    @apply line-clamp-2 hover:text-primary-500 transition-all duration-300;
  }
  .item-news .news-info {
    @apply flex flex-col;
  }
  .item-news .news-info .news-content {
    @apply line-clamp-2 text-sm;
  }
  .item-news .news-image .img-ratio {
    @apply ratio:pt-[160_240];
  }
  .item-news.horizontal {
    @apply grid grid-cols-[1fr_150px] md:grid-cols-[1fr_12.5rem] gap-5;
  }
  .item-news.horizontal.not-reverse {
    @apply grid-cols-[9.4791666667rem_1fr];
  }
  .item-news.horizontal.not-reverse .news-info {
    @apply order-2;
  }
  .item-news.horizontal .news-title {
    @apply flex-1 mb-0 md:mb-2 xs:max-md:border-b-0 xs:max-md:py-3 !important;
  }
  .item-news.horizontal .news-image .img-ratio {
    @apply mb-0;
  }
  .item-news.horizontal .news-info {
    @apply -order-1;
  }
  @media (max-width: 767.98px) {
    .item-news.horizontal .news-info .news-content {
      @apply hidden;
    }
  }
  .item-news.big .news-image .img-ratio {
    @apply ratio:pt-[408_680] mb-3;
  }
  .item-news.big .news-title {
    @apply text-2xl mb-4;
  }
  .item-news.big .news-content {
    @apply line-clamp-3;
  }
  .pagination-primary {
    @apply mt-10;
  }
  .pagination-primary ul {
    @apply flex items-center justify-center gap-3;
  }
  .pagination-primary ul li.active a {
    @apply bg-primary-500 text-white border-primary-500;
  }
  .pagination-primary ul li a {
    @apply text-neutral-500 border border-neutral-500 flex-center w-10 h-10 rounded-2;
    @apply hover:bg-primary-500 hover:text-white hover:border-primary-500;
  }
  .fancybox__slide {
    @apply py-12;
  }
  .tabslet-tab-secondary {
    @apply flex xl:gap-20 lg:gap-10 gap-5 border-b border-neutral-200 overflow-auto;
  }
  .tabslet-tab-secondary li.active a {
    @apply text-primary-500;
  }
  .tabslet-tab-secondary li a {
    @apply text-lg font-bold py-3 lg:py-2 block whitespace-nowrap;
  }
  .table-style-primary table tr {
    @apply border-b border-neutral-200 first:border-t;
  }
  .table-style-primary table tr td {
    @apply py-4;
    @apply text-primaryCustom-2 last:font-bold last:text-primaryCustom-1 last:text-right;
  }
  .pagination-style-secondary {
    @apply flex-center mt-4 static;
  }
  .pagination-style-secondary .swiper-pagination-bullet {
    @apply bg-primaryCustom-1 opacity-50 transition-all duration-300;
    @apply w-3 h-3 rounded-full flex-shrink-0;
  }
  .pagination-style-secondary .swiper-pagination-bullet-active {
    @apply bg-primary-500 opacity-100;
  }
  .popup-style-primary .carousel__button.is-close {
    top: 0.78125rem;
    right: 0.78125rem;
  }
  @media (max-width: 767.98px) {
    .popup-style-primary .carousel__button.is-close {
      top: 0;
      right: 0;
      transform: translate(25%, -30%);
      @apply bg-primary-500 !important;
    }
  }
  .popup-style-primary .carousel__button.is-close path {
    @apply stroke-white md:stroke-black rem:stroke-[1px];
  }
  .item-news-featured .img::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 22.5%, rgba(0, 0, 0, 0.7) 90.5%);
    @apply absolute top-0 left-0 right-0 bottom-0 z-2 pointer-events-none;
  }
  .input-icon {
    background-size: 0.9375rem 1.0416666667rem;
    background-position: center right 0.38125rem;
    background-repeat: no-repeat;
    background-image: url(../img/icon/user-white.svg);
  }
  .input-icon.email {
    background-image: url(../img/icon/envelope-white.svg);
  }
  .input-icon.phone {
    background-image: url(../img/icon/phone-white.svg);
  }
  .input-style-primary input {
    @apply py-4 px-2 border-b border-white text-white text-lg pr-10 bg-transparent w-full placeholder:text-white;
    @apply outline-none hocus:border-primary-600 transition-all duration-300;
  }
  .wrap-tools-social li:hover img {
    @apply filter-white;
  }
  .table-compare-style {
    @apply border border-neutral-200 rounded-3 w-fit min-w-full;
  }
  .table-compare-style table {
    @apply border-collapse rounded-3 overflow-hidden border border-neutral-200;
  }
  .table-compare-style table td, .table-compare-style table th {
    @apply px-5 py-3;
  }
  .table-compare-style table thead {
    @apply rounded-t-3 overflow-hidden;
  }
  .table-compare-style table thead tr th {
    @apply bg-primary-500 text-white text-center border-r border-neutral-200 whitespace-nowrap border;
  }
  .table-compare-style table thead tr th:first-child {
    @apply text-left rounded-tl-3;
  }
  .table-compare-style table thead tr th:last-child {
    @apply text-left rounded-tr-3 border-r-0 overflow-hidden;
  }
  .table-compare-style table tbody tr:nth-child(odd) td {
    @apply bg-white;
  }
  .table-compare-style table tbody tr:nth-child(even) td {
    @apply bg-neutral-50;
  }
  .table-compare-style table tbody tr:last-child td {
    @apply border-b-0;
  }
  .table-compare-style table tbody tr td {
    @apply border-b border-r border-neutral-200 text-center;
  }
  .table-compare-style table tbody tr td[class*=bg-primary-500] {
    @apply bg-primary-500 text-white;
  }
  .table-compare-style table tbody tr td .fa-circle-check {
    @apply text-[#10c900] text-xl font-black;
  }
  .table-compare-style table tbody tr td .fa-circle-xmark {
    @apply text-xl text-[#B0BDCB];
  }
  .table-compare-style table tbody tr td:first-child {
    @apply text-left;
  }
}
body.focus-form::before {
  content: "";
  @apply fixed top-0 left-0 right-0 bottom-0 bg-black/30 backdrop-blur-sm z-50;
}
body.focus-form #form-contact {
  @apply z-[100];
}

@layer utilities {
  .section-py {
    @apply py-10 lg:py-15 xl:py-20;
  }
  .no-gutters {
    @apply mx-0;
  }
  .no-gutters > * {
    @apply px-0;
  }
  .lozad {
    opacity: 0;
    filter: blur(10px);
    transition: 0.3s all ease-in-out;
  }
  .lozad[data-loaded=true] {
    opacity: 1;
    filter: blur(0);
  }
  .scroll-mandatory {
    scroll-snap-type: x mandatory;
  }
  .scroll-mandatory > * {
    scroll-snap-align: start;
  }
  .desktop-hidden {
    @apply flex md:hidden !important;
  }
  .mobile-hidden {
    @apply hidden md:flex !important;
  }
  .tabs-center li {
    @apply first:ml-auto last:mr-auto;
  }
  .scroll-style-primary::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    @apply rounded-full;
  }
  .scroll-style-primary::-webkit-scrollbar-track {
    background: #DCDCDC;
    @apply rounded-full;
  }
  .scroll-style-primary::-webkit-scrollbar-thumb {
    background: #00AEEF;
    @apply rounded-full;
  }
  .img-cover img {
    @apply w-full h-full object-cover !important;
  }
}
.btn {
  @apply cursor-pointer select-none transition-all duration-300;
}
.btn.btn-solid {
  @apply rem:rounded-[8px] h-[40px] lg:rem:h-[42px] px-4 text-lg font-bold border border-neutral-50 flex items-center;
  @apply transition-all duration-300 hover:bg-primary-500 hover:border-primary-500;
}
.btn.btn-sw-1 svg {
  @apply transition-all duration-300 lg:w-6 w-5;
}
.btn.btn-sw-1 svg path {
  @apply transition-all duration-300;
}
.btn.btn-sw-1:hover svg {
  @apply scale-110;
}
.btn.btn-sw-1:hover svg path {
  fill-opacity: 1;
  @apply fill-white;
}
.btn.btn-primary {
  @apply bg-primary-500 text-white h-[46px] md:h-12.5 px-[24px] md:px-6 font-medium uppercase rounded-2 flex-center;
  @apply transition-all duration-300 gap-2;
}
.btn.btn-primary[class*=uppercase] span {
  @apply uppercase !important;
}
.btn.btn-primary.transparent {
  @apply bg-transparent border border-white hover:border-primary-600;
}
.btn.btn-primary.transparent span {
  @apply text-lg font-medium normal-case;
}
.btn.btn-primary:hover {
  @apply bg-primary-600;
}
.btn.btn-primary:hover .icon::before {
  @apply translate-x-[125%];
}
.btn.btn-primary:hover .icon::after {
  @apply translate-x-0;
}
.btn.btn-primary .icon {
  @apply w-[14px] h-[15px] xl:rem:w-[16px] xl:rem:h-[16px] relative overflow-hidden flex-shrink-0 flex items-center text-base;
}
.btn.btn-primary .icon::before {
  @apply translate-x-0;
}
.btn.btn-primary .icon::after {
  @apply -translate-x-[125%];
}
.btn.btn-primary .icon::before, .btn.btn-primary .icon::after {
  content: "\f178";
  display: inline-block;
  @apply text-white font-light rem:text-[16px];
  @apply absolute inline-block font-awesome transition-all duration-300;
}
.btn.btn-primary.gray {
  @apply border border-neutral-500 text-neutral-500 hover:bg-primary-500 hover:text-white;
}
.btn.btn-primary.gray:hover .icon::before, .btn.btn-primary.gray:hover .icon::after {
  @apply text-white;
}
.btn.btn-primary.gray .icon::before, .btn.btn-primary.gray .icon::after {
  @apply text-neutral-500;
}
.btn.btn-sw-2 {
  @apply text-2xl;
}
.btn.btn-sw-3 {
  @apply w-10 h-10 border border-neutral-500 flex-center rounded-2;
  @apply transition-all duration-300 hover:bg-primary-500 hover:text-white;
  @apply hover:border-primary-500;
}
.btn.btn-sw-3.white {
  @apply border-white;
}
.btn.btn-sw-3.white::before {
  @apply text-white;
}
.btn.btn-sw-3:hover::before {
  @apply text-white;
}
.btn.btn-sw-3::before {
  @apply font-awesome font-light text-neutral-500 transition-all duration-300;
}
.btn.btn-sw-3.btn-prev::before {
  content: "\f177";
}
.btn.btn-sw-3.btn-next::before {
  content: "\f178";
}
.btn.btn-view-more {
  @apply text-lg font-medium text-primary-500 gap-2 flex items-center hover:text-primary-600;
}
.btn.btn-view-more.active::after {
  @apply rotate-180;
}
.btn.btn-view-more.arrow-right.active::after {
  @apply -rotate-90;
}
.btn.btn-view-more.arrow-right::after {
  content: "\f105";
  @apply translate-y-1;
}
.btn.btn-view-more::after {
  content: "\f107";
  @apply font-awesome font-light transition-all duration-300;
}
.btn.btn-video {
  box-shadow: 0 0 0 0px rgba(0, 144, 212, 0.25), 0 0 0 0px rgba(0, 144, 212, 0.2);
  @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
  @apply w-15 h-15 flex-center rounded-full text-white;
  @apply transition-all duration-300 z-10;
}
.btn.btn-video::after {
  content: "";
  box-shadow: 0 0 0 11.5px rgba(0, 144, 212, 0.25), 0 0 0 20px rgba(0, 144, 212, 0.2);
  animation: btn-video-pulse 2s infinite 1s ease-out;
  @apply absolute left-0 bottom-0 w-full h-full rounded-full -z-1;
}
.btn.btn-video::before {
  content: "\f04b";
  @apply font-awesome text-2xl font-black absolute w-full h-full flex-center z-2 bg-primary-500 rounded-full translate-x-0 translate-y-0 pl-1;
}
.btn.btn-double {
  @apply flex;
}
.btn.btn-double a {
  @apply text-sm font-medium flex-center rem:min-h-[34px] px-4 border border-primary-500 rem:min-w-[100px] first:rounded-l-2 last:rounded-r-2;
}
.btn.btn-double a.active {
  @apply bg-primary-500 text-white;
}

@keyframes btn-video-pulse {
  0% {
    transform: scale(0.5);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
.expand-content-text.show > * {
  @apply block !important;
}
.expand-content-text > p:first-child {
  @apply block;
}
.expand-content-text > * {
  @apply hidden;
}

.expand-content-item.show > [class*=item-utility]:nth-child(n+10) {
  @apply flex !important;
}
.expand-content-item > [class*=item-utility]:nth-child(n+10) {
  @apply hidden;
}

.expand-content-table.show table tbody tr:nth-child(n+10) {
  @apply block !important;
}
.expand-content-table table tbody tr:nth-child(n+10) {
  @apply hidden;
}

.form-filter {
  @apply relative;
}
@media (max-width: 1023.98px) {
  .form-filter {
    @apply fixed top-[60px] left-0 h-[calc(100dvh-60px)] z-[999] bg-white p-5 flex flex-col gap-5 w-screen overflow-auto translate-x-full;
    transition: all 0.6s cubic-bezier(0.75, 0, 0.175, 1);
  }
  .form-filter.isOpen {
    @apply translate-x-0;
  }
  .form-filter .wrap-form-group {
    @apply flex flex-col gap-5 flex-1 overflow-auto;
  }
  .form-filter .form-submit button {
    @apply flex-1 h-[45px] !important;
  }
  .form-filter .label-title {
    @apply mb-4 text-[16px];
  }
}
.form-filter .label-title {
  @apply font-bold text-primary-500 block;
}
@media (max-width: 1023.98px) {
  .form-filter .form-group-select {
    @apply flex-none;
  }
  .form-filter .form-group-select .select-options {
    @apply block;
    @apply static shadow-none bg-transparent min-w-fit px-0 py-5 !important;
  }
}
.form-filter .form-group-select.full-wide {
  @apply static;
}
.form-filter .form-group-select.full-wide .select-options {
  @apply w-full py-10 px-10;
}
.form-filter .form-group-select.full-wide .form-group {
  @apply relative;
}
.form-filter .form-group-select.full-wide .form-group::after {
  content: "";
  @apply absolute top-full w-[110%] h-[1px] bg-neutral-200 left-1/2 -translate-x-1/2 md:opacity-0 opacity-100;
}
.form-filter .form-group-select.full-wide .form-group:nth-child(1)::after, .form-filter .form-group-select.full-wide .form-group:nth-child(2)::after, .form-filter .form-group-select.full-wide .form-group:nth-child(3)::after {
  @apply opacity-100;
}
.form-filter .form-group-select.full-wide .form-group:nth-child(2)::before, .form-filter .form-group-select.full-wide .form-group:nth-child(3)::before, .form-filter .form-group-select.full-wide .form-group:nth-child(5)::before {
  content: "";
  @apply absolute rem:-left-[20px] top-0 h-[calc(100%-20px)] w-[1px] bg-neutral-200;
}
.form-filter .form-group-select.isOpen .select-button i {
  @apply rotate-180;
}
.form-filter .form-group-select.isOpen .select-options {
  @apply block;
}
.form-filter .form-group-select .select-button {
  @apply flex items-center justify-between rem:h-[42px] px-5 border border-neutral-200 rounded-2 text-lg font-medium cursor-pointer whitespace-nowrap gap-4;
}
.form-filter .form-group-select .select-button i {
  @apply text-xl text-neutral-500 font-light transition-all duration-300;
}
.form-filter .form-group-select .select-options {
  box-shadow: 4px 4px 32px 16px rgba(0, 0, 0, 0.08);
  @apply top-full translate-y-2 left-0 absolute w-full bg-white z-[99] p-5 rounded-2 gap-5 xs:max-lg:min-w-[300px];
  @apply lg:hidden;
}
.form-filter .form-group-select .select-options .wrap {
  @apply max-h-[10rem] lg:rem:max-h-[200px] overflow-auto pr-5;
  @apply flex flex-col gap-5 overflow-y-auto;
}
.form-filter .form-group-select .select-options .wrap::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  @apply rounded-full;
}
.form-filter .form-group-select .select-options .wrap::-webkit-scrollbar-track {
  background: #DCDCDC;
  @apply rounded-full;
}
.form-filter .form-group-select .select-options .wrap::-webkit-scrollbar-thumb {
  background: #00AEEF;
  @apply rounded-full;
}
.form-filter .form-group-select .select-options .select-options-item {
  @apply flex items-center justify-between;
}
.form-filter .form-group-select .select-options .select-options-item label {
  @apply cursor-pointer flex items-center justify-between w-full transition-all duration-300 rem:min-h-[24px];
  @apply hover:text-primary-500;
}
.form-filter .form-group-select .select-options .select-options-item label::after {
  content: "\f0c8";
  @apply font-awesome font-light text-primaryCustom-1;
}
.form-filter .form-group-select .select-options .select-options-item input[type=checkbox], .form-filter .form-group-select .select-options .select-options-item input[type=radio] {
  @apply hidden;
}
.form-filter .form-group-select .select-options .select-options-item input[type=checkbox]:checked ~ label::after, .form-filter .form-group-select .select-options .select-options-item input[type=radio]:checked ~ label::after {
  content: "\f14a";
  @apply text-primary-500 font-black;
}
.form-filter .form-submit {
  @apply text-sm font-medium flex;
}
.form-filter .form-submit i {
  @apply text-base font-light;
}
.form-filter .form-submit .btn {
  @apply flex items-center gap-5 rem:h-[42px] px-2 rem:min-w-[100px] justify-center hover:bg-primary-500 hover:text-white whitespace-nowrap;
}
.form-filter .form-submit .btn-submit, .form-filter .form-submit .btn-delete, .form-filter .form-submit .btn-close {
  @apply flex items-center gap-2 text-primary-500 border border-primary-500 rounded-l-2;
}
.form-filter .form-submit .btn-delete {
  @apply rounded-l-none md:rounded-r-2 bg-primary-500 text-white;
}
.form-filter .form-submit .btn-close {
  @apply rounded-l-none rounded-r-2 bg-red-500 border-red-500 text-white hover:bg-red-600 hover:border-red-600;
}
.form-filter .f-checkbox-group {
  @apply pb-8;
}
.form-filter .f-checkbox-group .wrap-group-checkbox {
  @apply grid grid-cols-2 gap-3 mt-3;
}
.form-filter .f-checkbox-group .label-title {
  @apply col-span-full w-full;
}
.form-filter .f-checkbox-group .f-checkbox label {
  @apply cursor-pointer flex items-center w-full transition-all duration-300 gap-3 text-[14px] lg:text-sm leading-none;
  @apply hover:text-primary-500;
}
.form-filter .f-checkbox-group .f-checkbox label::before {
  content: "\f0c8";
  @apply font-awesome font-light text-[#838383];
}
.form-filter .f-checkbox-group .f-checkbox input[type=checkbox], .form-filter .f-checkbox-group .f-checkbox input[type=radio] {
  @apply hidden;
}
.form-filter .f-checkbox-group .f-checkbox input[type=checkbox]:checked ~ label::before, .form-filter .f-checkbox-group .f-checkbox input[type=radio]:checked ~ label::before {
  content: "\f14a";
  @apply text-primary-500 font-black;
}
.form-filter .f-slider-range {
  @apply py-3 md:py-0;
}
.form-filter .f-slider-range #slider-acreage {
  box-shadow: none;
  @apply w-full h-1 bg-neutral-400 border-0 rounded-full overflow-visible;
}
.form-filter .f-slider-range #slider-acreage .noUi-connect {
  @apply bg-primary-500;
}
.form-filter .f-slider-range #slider-acreage .noUi-handle {
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.36));
  box-shadow: none;
  @apply w-[14px] h-[14px] bg-white rem:-right-[6px] rounded-full border border-neutral-50 hover:bg-primary-500 transition-all duration-300 border-white  !important;
  @apply cursor-pointer;
}
.form-filter .f-slider-range #slider-acreage .noUi-handle::before, .form-filter .f-slider-range #slider-acreage .noUi-handle::after {
  @apply hidden;
}
.form-filter .f-slider-range .slider-number {
  @apply flex items-center;
}
.form-filter .f-slider-range .slider-number.max {
  @apply text-primary-500;
}
.form-filter .f-slider-range .slider-number input {
  @apply hidden;
}

.loading-filter {
  @apply fixed top-0 left-0 w-full h-full bg-black/20 backdrop-blur-sm z-[999];
}
.loading-filter .loading-filter-inner {
  animation-duration: 0.35s;
  @apply w-[50px] h-[50px] border-[6px] border-primary-500 border-t-white rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ease-linear;
  @apply animate-spin;
}

.invalid-feedback {
  @apply text-red-500 text-sm pt-2;
}

.form-control.is-invalid {
  @apply border-red-500 !important;
}

.form-message .alert {
  @apply border-4  bg-white rounded-2 overflow-hidden p-2 mt-3 mb-3;
}
.form-message .alert.alert-danger {
  @apply border-red-500;
}
.form-message .alert.alert-danger ul {
  @apply flex flex-col text-sm text-red-500 gap-1;
}
.form-message .alert.alert-success {
  @apply text-green-500 border-green-500;
}
.form-message .alert.alert-info {
  @apply text-primary-600 border-primary-600;
}

.title-40px {
  @apply text-[26px] lg:text-40 font-bold;
}

.title-48px {
  @apply text-[32px] md:text-5xl lg:text-6xl font-bold leading-tight;
}

.title-32px {
  @apply text-[24px] md:text-32 font-bold;
}

.title-24px {
  @apply text-[18px] md:text-2xl font-bold;
}

.title-20px {
  @apply text-[16px] md:text-xl font-bold;
}

.section-contact .item {
  box-shadow: 4px 4px 32px 16px rgba(0, 0, 0, 0.08);
}
.section-contact .wrap-form {
  @apply lg:pl-10 !important;
}
.section-contact .popup-desc {
  @apply text-neutral-700;
}

.global-breadcrumb {
  @apply border-b border-neutral-200 overflow-auto;
}
.global-breadcrumb ul {
  @apply flex items-center h-12.5;
}
.global-breadcrumb ul li + li::before {
  content: "\f105";
  @apply font-awesome font-light text-neutral-400 mx-4;
}
.global-breadcrumb ul li:first-child a {
  @apply text-0 leading-[1] !important;
}
.global-breadcrumb ul li:first-child a::before {
  content: "\f015";
  @apply font-awesome text-xl font-light text-neutral-400;
}
.global-breadcrumb ul li a, .global-breadcrumb ul li span {
  @apply text-lg font-medium text-neutral-400;
}

footer .footer-list {
  @apply flex flex-col gap-3;
}
footer .footer-list li {
  @apply flex items-center gap-2;
}
footer .footer-title {
  @apply text-lg font-bold;
}
footer .footer-list-address {
  @apply flex flex-col gap-3;
}
footer .footer-list-address li {
  @apply flex gap-2;
}
footer .footer-list-links ul {
  @apply flex flex-col gap-3;
}
footer .footer-list-links ul a {
  @apply hover:text-primary-500;
}
footer .footer-list-social {
  @apply flex items-center gap-8;
}
footer .footer-list-social li a {
  @apply text-xl text-white/50 transition-all duration-300 hover:text-primary-500;
}
@media (max-width: 1023.98px) {
  footer .col:last-child .col-footer-links {
    @apply border-b border-neutral-700;
  }
}
@media (max-width: 767.98px) {
  footer .col-footer-links {
    @apply border-t border-neutral-700;
  }
  footer .col-footer-links.open .footer-title .icon-dropdown {
    @apply rotate-180;
  }
  footer .col-footer-links .footer-title {
    @apply flex items-center justify-between mb-0 py-4 text-[18px];
  }
  footer .col-footer-links .footer-title .icon-dropdown {
    @apply transition-all duration-300;
  }
  footer .col-footer-links .footer-title .icon-dropdown::before {
    content: "\f078";
    @apply font-awesome text-white text-[18px] font-normal;
  }
  footer .col-footer-links .footer-list-links {
    @apply hidden pb-5;
  }
}

.tool-cta {
  @apply z-[999];
}
.tool-cta .button-to-top {
  @apply hidden;
}
.tool-cta .button-to-top.active {
  @apply block;
}
.tool-cta .btn-cta {
  @apply px-3;
}
.tool-cta .btn-cta img {
  @apply max-w-full;
}

@media (max-width: 1023.98px) {
  header .header-menu-mobile {
    @apply fixed top-[70px] bg-primaryCustom-1 w-[95vw] left-1/2 -translate-x-1/2 rounded-4 p-5;
    @apply opacity-100 invisible translate-x-[100%];
    @apply shadow-md border-4 border-white/30;
    transition: all 0.6s cubic-bezier(0.75, 0, 0.175, 1);
  }
  header .header-menu-mobile .header-menu {
    @apply order-2 border-t border-neutral-50/20 pt-5 w-full pb-5 border-b;
  }
  header .header-menu-mobile .header-action {
    @apply order-1 w-full text-center justify-center mb-5;
  }
  header .header-menu-mobile .header-language {
    @apply order-3;
  }
}
header .header-menu li[class*=current] a {
  @apply text-primary-500;
}
@media (max-width: 1023.98px) {
  header .header-menu {
    @apply flex flex-col gap-5;
  }
  header .header-menu li a {
    @apply text-3xl font-semibold hover:text-primary-500  flex items-center gap-2;
  }
}
@screen lg {
  header .header-menu {
    @apply flex items-center gap-12;
  }
  header .header-menu li a {
    @apply text-lg font-semibold hover:text-primary-500 flex items-center gap-2;
  }
}
header .btn-solid {
  @apply font-semibold;
}
header .header-language {
  @apply relative;
}
header .header-language:hover .lang-list {
  @apply opacity-100 visible;
}
header .header-language .lang-active {
  @apply px-4 rem:h-[42px] flex-center gap-2 text-lg font-bold border border-white rounded-2;
}
header .header-language .lang-active::after {
  content: "\f078";
  @apply font-awesome text-sm font-light;
}
header .header-language .lang-list {
  @apply absolute top-full left-0 w-full bg-white rem:rounded-2 text-[#333333] text-lg font-semibold translate-y-1 shadow-md overflow-hidden transition-all duration-300;
  @apply opacity-0 invisible;
}
header .header-language .lang-list li a {
  @apply block rem:py-2 rem:px-4 hover:bg-primary-500 hover:text-white transition-all duration-300;
}
header .header-search > * {
  @apply pointer-events-none;
}

body.isOpenMenu header .header-menu-mobile {
  @apply opacity-100 visible -translate-x-1/2;
}

.header-hambuger {
  @apply flex flex-col gap-2 ml-7 md:ml-11 lg:ml-11 xl:ml-[calc(58/19.2*1rem)] cursor-pointer z-10 relative lg:hidden;
}
.header-hambuger span {
  @apply transition-all duration-300 pointer-events-none;
  @apply w-7 h-1 bg-white;
}
.header-hambuger span:nth-child(1) {
  transform-origin: center left;
}
.header-hambuger span:nth-child(2) {
  @apply opacity-100;
}
.header-hambuger span:nth-child(3) {
  transform-origin: center left;
}
.header-hambuger #pulseMe {
  transform-origin: center center;
  @apply pointer-events-none;
  @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[38px] h-[38px] absolute;
  @apply bg-[#252525] -z-1;
}
.header-hambuger #pulseMe .bar {
  box-shadow: 0 0 0 #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 4px #ffffff;
  @apply absolute bg-white;
}
.header-hambuger #pulseMe .bar.left {
  animation: leftBar 2s linear infinite;
  @apply w-[1px];
}
.header-hambuger #pulseMe .bar.top {
  animation: topBar 2s linear infinite;
  @apply h-[1px] w-0;
}
.header-hambuger #pulseMe .bar.right {
  animation: rightBar 2s linear infinite;
  @apply w-[1px];
}
.header-hambuger #pulseMe .bar.bottom {
  animation: bottomBar 2s linear infinite;
  @apply h-[1px];
}

body.isOpenMenu header .header-hambuger span:nth-child(1) {
  rotate: 45deg;
  translate: 0.2083333333rem 0.15625rem;
}
body.isOpenMenu header .header-hambuger span:nth-child(2) {
  @apply opacity-0;
}
body.isOpenMenu header .header-hambuger span:nth-child(3) {
  rotate: -45deg;
  translate: 0.2083333333rem -0.0520833333rem;
}

@keyframes leftBar {
  0% {
    height: 0;
    left: 0;
    top: 100%;
  }
  20% {
    height: 100%;
    left: 0;
    top: 0;
  }
  40% {
    height: 0;
    left: 0;
    top: 0;
  }
}
@keyframes topBar {
  0%, 20% {
    left: 0;
    top: 0;
    width: 0;
  }
  40% {
    left: 0;
    top: 0;
    width: 100%;
  }
  60% {
    left: calc(100% - 1px);
    top: 0;
    width: 0;
  }
}
@keyframes rightBar {
  0%, 40% {
    height: 0;
    left: calc(100% - 1px);
    top: 0;
  }
  60% {
    height: 100%;
    left: calc(100% - 1px);
    top: 0;
  }
  80% {
    height: 0;
    left: calc(100% - 1px);
    top: 100%;
  }
}
@keyframes bottomBar {
  0%, 60% {
    left: 100%;
    top: 100%;
    width: 0;
  }
  80% {
    left: 0;
    top: 100%;
    width: 100%;
  }
  100% {
    left: 0;
    top: 100%;
    width: 0;
  }
}
.header-search-form {
  @apply -translate-y-20 pointer-events-none invisible transition-all duration-300;
  @apply shadow-md border-4 border-white/30;
}
.header-search-form.active {
  @apply translate-y-2 pointer-events-auto visible;
}
.header-search-form input {
  @apply flex-1 bg-white bg-transparent transition-all duration-300 pr-15 placeholder:text-white/50 text-white py-3 pl-8;
  @apply outline-none;
}
.header-search-form button.btn-search {
  @apply text-white absolute left-0;
}
.header-search-form button.close {
  @apply absolute right-0 text-white text-2xl flex mt-1;
}

.section-about-3 .col-slider-year::before {
  content: "";
  @apply w-[200vw] h-full bg-primaryCustom-1 absolute left-1/2 top-0 -translate-x-1/2 -z-1;
}
.section-about-3 .wrapper-slider-year::before {
  content: "";
  @apply absolute rem:top-[6px] left-0 w-full h-[1px] bg-[#BDBDBD];
}
.section-about-3 .wrapper-slider-year .process-bar-year {
  @apply absolute rem:top-[6px] left-0 h-[1px] bg-primary-500 w-[var(--process)];
  @apply transition-all duration-300 ease-linear;
}
.section-about-3 .wrapper-slider-year .swiper-slide {
  @apply relative;
}
.section-about-3 .wrapper-slider-year .swiper-slide .circle-year {
  @apply transition-all duration-300;
}
.section-about-3 .wrapper-slider-year .swiper-slide.swiper-slide-thumb-active .item-year .circle-year {
  @apply relative;
  @apply bg-primary-500;
}
.section-about-3 .wrapper-slider-year .swiper-slide.swiper-slide-thumb-active .item-year .text-year {
  @apply text-primary-500;
}

.section-about-2 .section-vision .row {
  @apply lg:-mx-3;
}
.section-about-2 .section-vision .col {
  @apply lg:px-3;
}

.section-home-banner .wrap-button-custom-slide .btn-sw-1 {
  @apply absolute top-[60%] md:top-1/2 -translate-y-1/2 z-10 opacity-40 hover:opacity-100;
}
.section-home-banner .wrap-button-custom-slide .btn-sw-1.btn-prev {
  @apply lg:left-8 left-[5px];
}
.section-home-banner .wrap-button-custom-slide .btn-sw-1.btn-next {
  @apply lg:right-8 right-[5px];
}

.section-home-property-selling .wrap-item-product {
  @apply relative;
}
.section-home-property-selling .wrap-item-product .item-product-image-full {
  @apply absolute top-0 left-0 w-full h-full bg-transparent object-cover transition-all duration-300 opacity-0;
}
.section-home-property-selling .wrap-item-product.hover .item-product-image-full {
  @apply opacity-100;
}
.section-home-property-selling .wrap-item-product.hover .item-product-secondary img {
  @apply opacity-0;
}

.section-home-property-transfer .wrap-product-small > div {
  @apply h-full;
}
.section-home-property-transfer .item-product:not(.big) {
  @apply xs:max-lg:flex-[0_0_264px];
}

.fancybox__slide {
  @apply px-5;
}

.popup-form-contact {
  @apply w-[95vw] xl:rem:max-w-[1440px] xl:w-[100%] p-4 rem:rounded-[24px]  relative z-2;
}
.popup-form-contact .popup-desc {
  @apply text-neutral-700;
}
.popup-form-contact .carousel__button.is-close {
  top: 0.78125rem;
  right: 0.78125rem;
}
@media (max-width: 767.98px) {
  .popup-form-contact .carousel__button.is-close {
    top: 0;
    right: 0;
    transform: translate(25%, -30%);
    @apply bg-primary-500 !important;
  }
}
.popup-form-contact .carousel__button.is-close path {
  @apply stroke-white md:stroke-black rem:stroke-[1px];
}
.popup-form-contact::before {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 174, 239, 0.8), rgba(0, 100, 137, 0.2));
  @apply absolute top-0 left-0 w-full h-full bg-black/50 -z-1 rem:rounded-[24px];
}

.popup-form-wrapper {
  @apply bg-white rem:rounded-[12px] overflow-hidden md:p-10 p-5;
}
.popup-form-wrapper .popup-image {
  @apply hidden md:block;
}
.popup-form-wrapper .popup-image .img {
  @apply md:min-h-0 lg:rem:min-h-[477px] h-full;
}
.popup-form-wrapper .popup-image .img img {
  @apply w-full h-full object-cover;
}
@media (max-width: 767.98px) {
  .popup-form-wrapper .g-recaptcha {
    @apply flex justify-end w-full;
  }
}
.popup-form-wrapper .frm-submit {
  @apply items-center;
}
.popup-form-wrapper .popup-form {
  @apply md:grid md:grid-cols-2 gap-6 flex flex-col;
}
.popup-form-wrapper .popup-form .form-group .form-label {
  @apply hidden;
}
.popup-form-wrapper .popup-form .form-group input[type=text], .popup-form-wrapper .popup-form .form-group input[type=tel], .popup-form-wrapper .popup-form .form-group input[type=email] {
  background-image: url(../img/icon/user.svg);
  background-size: 0.9375rem 1.0416666667rem;
  background-position: center right 0.38125rem;
  background-repeat: no-repeat;
  @apply py-4 px-2 border-b border-neutral-200 text-lg font-medium w-full outline-none ring-0 rounded-none;
  @apply hocus:border-primary-500 transition-all;
}
.popup-form-wrapper .popup-form .form-group.f-phone input {
  background-image: url(../img/icon/phone.svg);
}
.popup-form-wrapper .popup-form .form-group.f-email {
  @apply col-span-2;
}
.popup-form-wrapper .popup-form .form-group.f-email input {
  background-image: url(../img/icon/envelope.svg);
}
.popup-form-wrapper .popup-form .form-group.f-checkbox {
  @apply col-span-2;
}
.popup-form-wrapper .popup-form .multi-check-list-wrapper {
  @apply flex items-center gap-2 flex-wrap;
}
.popup-form-wrapper .popup-form .multi-check-list-wrapper .form-check {
  @apply flex-1 flex items-center gap-3;
}
.popup-form-wrapper .popup-form .multi-check-list-wrapper .form-check .form-check-label {
  @apply flex items-center gap-3 cursor-pointer whitespace-nowrap text-neutral-700;
}
.popup-form-wrapper .popup-form .multi-check-list-wrapper .form-check .form-check-label::before {
  content: "\f0c8";
  @apply text-xl font-light text-[#BDBDBD] font-awesome;
}
.popup-form-wrapper .popup-form .multi-check-list-wrapper .form-check input {
  @apply hidden;
}
.popup-form-wrapper .popup-form .multi-check-list-wrapper .form-check input:checked ~ span::before {
  content: "\f14a";
  @apply font-black text-primary-500;
}
.popup-form-wrapper .popup-form .frm-submit {
  @apply justify-end;
}

.form-group .form-label {
  @apply hidden;
}

.section-house-consignment-6 .item-toggle.active {
  @apply bg-white border border-primary-500;
}
.section-house-consignment-6 .item-toggle.active .title {
  @apply text-primary-500;
}
.section-house-consignment-6 .item-toggle.active .title::after {
  content: "\f068";
}
.section-house-consignment-6 .item-toggle .title::after {
  content: "+";
  @apply text-lg font-black font-awesome;
}

@media (max-width: 767.98px) {
  .section-house-consignment-7 .container {
    @apply px-0;
  }
}
.section-house-consignment-7 .col-content::before {
  content: "";
  background-image: url(../img/popup.png);
  @apply absolute left-0 top-0 w-full md:w-[120%] lg:w-[50vw] h-full -z-1 pointer-events-none;
}
.section-house-consignment-7 .col-content .wrap-form .popup-form {
  @apply gap-3;
}
.section-house-consignment-7 .col-content .wrap-form .popup-form .form-group.f-email input {
  background-image: url(../img/icon/envelope-white.svg) !important;
}
.section-house-consignment-7 .col-content .wrap-form .popup-form .form-group.f-phone input {
  background-image: url(../img/icon/phone-white.svg) !important;
}
.section-house-consignment-7 .col-content .wrap-form .popup-form .form-group input {
  background-image: url(../img/icon/user-white.svg) !important;
  @apply rem:min-h-[48px] py-2 bg-transparent text-white placeholder:text-white text-base !important;
  @apply hocus:border-primary-300;
}
.section-house-consignment-7 .col-content .wrap-form .popup-form textarea {
  @apply outline-none ring-0 px-2;
  @apply hocus:border-primary-300 !important;
  @apply rem:min-h-[120px] py-2 bg-transparent text-white placeholder:text-white text-base border-b border-white w-full !important;
}

.section-buy-detail .product-title-specs {
  @apply whitespace-nowrap flex-wrap;
}
.section-buy-detail #form-contact {
  @apply overflow-visible !important;
}
.section-buy-detail #form-contact .g-recaptcha {
  @apply w-full max-w-full !important;
}
.section-buy-detail #form-contact .g-recaptcha * {
  @apply w-full max-w-full !important;
}
.section-buy-detail #form-contact.active::before {
  @apply opacity-100;
}
.section-buy-detail #form-contact::before {
  content: "\e1a1";
  animation: arrow-right 1s infinite ease-in-out;
  @apply absolute top-1/2 -translate-y-1/2 right-full mr-5 text-primary-500 rem:text-[42px] font-awesome opacity-0 transition-opacity duration-300;
}
.section-buy-detail.section-banner .wrap-thumb-banner {
  @apply px-9 md:px-12.5 relative;
}
.section-buy-detail.section-banner .wrap-thumb-banner .btn-sw-2 {
  @apply absolute top-1/2 -translate-y-1/2;
}
.section-buy-detail.section-banner .wrap-thumb-banner .btn-sw-2.btn-prev {
  @apply left-0;
}
.section-buy-detail.section-banner .wrap-thumb-banner .btn-sw-2.btn-next {
  @apply right-0;
}
.section-buy-detail.section-banner .wrap-thumb-banner .swiper-slide .item-thumb-banner {
  @apply border-[2px] border-transparent;
}
.section-buy-detail.section-banner .wrap-thumb-banner .swiper-slide.swiper-slide-thumb-active .item-thumb-banner {
  @apply border-primary-500;
}
@media (max-width: 767.98px) {
  .section-buy-detail.section-banner .wrap-btn-tab {
    @apply absolute bottom-[115px] flex-center w-full z-10 gap-2;
  }
  .section-buy-detail.section-banner .wrap-btn-tab .btn-tab-banner {
    @apply flex-center border-2 border-white rounded-full w-[35px] h-[35px] p-0 min-w-0 border-solid  !important;
  }
  .section-buy-detail.section-banner .wrap-btn-tab .btn-tab-banner .icon {
    @apply w-full h-full flex-center;
  }
  .section-buy-detail.section-banner .wrap-btn-tab .btn-tab-banner .icon img {
    @apply w-5 h-5 filter-white;
  }
  .section-buy-detail.section-banner .wrap-btn-tab .btn-tab-banner .text {
    @apply hidden;
  }
}
.section-buy-detail.section-banner .wrap-btn-tab .btn-tab-banner.active {
  @apply bg-primaryCustom-1 text-white;
}
.section-buy-detail.section-banner .wrap-btn-tab .btn-tab-banner.active img {
  @apply filter-white;
}
.section-buy-detail.section-information .product-title-specs li {
  @apply flex items-center;
  @apply text-[#3B3B3B];
}
.section-buy-detail.section-information .product-title-specs li i, .section-buy-detail.section-information .product-title-specs li span {
  @apply text-[#3B3B3B];
}
.section-buy-detail.section-information .product-title-specs li + li::before {
  content: "";
  @apply w-[1px] h-[24px] bg-neutral-300 inline-block mx-2 md:mx-4;
}
@screen lg {
  .section-buy-detail .wrap-tools-social {
    @apply absolute top-10 h-full -translate-x-[calc(100%+2.0833333333rem)];
  }
}
.section-buy-detail .wrap-tools-social ul {
  @apply sticky top-[100px];
}
.section-buy-detail .wrap-tools-social li a {
  @apply p-2;
}
.section-buy-detail .wrap-tools-social li img {
  @apply max-w-full;
}
.section-buy-detail .wrap-tools-social li:hover img {
  @apply filter-white;
}
.section-buy-detail .tab-utilities-area .item-utility-area table {
  @apply w-full;
}
.section-buy-detail .tab-utilities-area .item-utility-area table tr td {
  @apply first:text-left last:text-right last:font-bold py-2;
}
.section-buy-detail .wrap-form-contact .form-group {
  @apply mb-2;
}
.section-buy-detail .wrap-form-contact .form-group textarea {
  @apply bg-transparent w-full px-4 py-3 text-white text-base border-b border-white placeholder:text-white ring-0 outline-none rem:min-h-[119px];
}
.section-buy-detail .wrap-form-contact .form-group input:not([type=submit]) {
  background-image: url(../img/icon/user-white.svg);
  background-size: 0.9375rem 1.0416666667rem;
  background-position: center right 0.38125rem;
  background-repeat: no-repeat;
  @apply rounded-none;
  @apply bg-transparent w-full px-4 py-3 text-white text-base border-b border-white placeholder:text-white ring-0 outline-none;
}
.section-buy-detail .wrap-form-contact .form-group input:not([type=submit])[type=tel] {
  background-image: url(../img/icon/phone-white.svg);
}
.section-buy-detail .wrap-form-contact .form-group input:not([type=submit])[type=email] {
  background-image: url(../img/icon/envelope-white.svg);
}
.section-buy-detail .tab-project-detail .item-project-detail .img-ratio {
  @apply border border-[#B0BDCB];
}
.section-buy-detail .wrap-other-product .wrap-specs li {
  @apply whitespace-nowrap;
}

.popup-modal-3d {
  @apply p-5 max-w-[90w] w-full;
}
.popup-modal-3d .wrap-iframe {
  @apply img-ratio pt-[100%] lg:pt-[45.5%];
}

@keyframes arrow-right {
  0% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
section.section-news-detail .box-comment .wrap-form-comment textarea, section.section-news-detail .box-comment .wrap-form-comment input:not([type=hidden], [type=submit]) {
  @apply w-full rem:h-[48px] border border-neutral-200 rounded-2 px-6 py-5;
  @apply text-lg font-medium outline-none hocus:border-primary-500 transition-all duration-300;
}
section.section-news-detail .box-comment .wrap-form-comment textarea {
  @apply rem:h-[145px];
}
section.section-news-detail .wrap-toc.open .title-toc i.fa-solid.fa-chevron-down {
  @apply rotate-180;
}
section.section-news-detail .format-content img {
  @apply max-w-full;
}
section.section-news-detail .js-toc ol {
  counter-reset: item;
}
section.section-news-detail .js-toc ol li a::before {
  content: counters(item, ".") " .";
  counter-increment: item;
}
section.section-news-detail .js-toc > ol > li {
  @apply text-base font-bold text-primary-500;
}
section.section-news-detail .js-toc > ol > li > a {
  @apply mb-2 block;
}
section.section-news-detail .js-toc > ol > li ol {
  @apply pl-5;
}
section.section-news-detail .js-toc > ol > li ol li {
  @apply text-sm text-primaryCustom-1 font-light;
}
section.section-news-detail .js-toc > ol > li ol li a {
  @apply hover:bg-primary-200 rounded-2 transition-all duration-300 block w-full px-2 py-1 cursor-pointer;
}
section.section-news-detail .js-toc > ol > li ol li a.is-active-link {
  @apply bg-primary-200;
}

.fob-comment-form .row-cols-lg-2 {
  @apply flex flex-col mx-0 !important;
}
.fob-comment-form .row-cols-lg-2 .form-label {
  @apply hidden;
}

.popup-project-detail {
  @apply xs:max-md:p-5;
}

.section-buy-detail .section-products .item-product:nth-child(n+9) {
  @apply hidden;
}

.section-news-list .box-form-subscribe .content {
  background: linear-gradient(180deg, rgba(0, 174, 239, 0) 0%, #00A0DC 100%);
}
.section-news-list .box-form-subscribe .g-recaptcha {
  @apply mt-5 max-w-full;
}
.section-news-list .box-socials .number {
  @apply w-[20%] text-center;
}

.section-contact-form .form-white-transparent {
  @apply gap-3;
}
.section-contact-form .form-white-transparent .form-group.f-email input {
  background-image: url(../img/icon/envelope-white.svg) !important;
}
.section-contact-form .form-white-transparent .form-group.f-phone input {
  background-image: url(../img/icon/phone-white.svg) !important;
}
.section-contact-form .form-white-transparent .form-group input {
  background-image: url(../img/icon/user-white.svg) !important;
  @apply rem:min-h-[66px] py-2 bg-transparent text-white placeholder:text-white text-lg font-medium !important;
  @apply hocus:border-primary-600 !important;
}
.section-contact-form .form-white-transparent textarea {
  @apply outline-none ring-0 px-2;
  @apply hocus:border-primary-600 !important;
  @apply rem:min-h-[120px] py-2 bg-transparent text-white placeholder:text-white text-base border-b border-white w-full !important;
}