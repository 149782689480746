.form-filter
	@apply relative
	@media (max-width: 1023.98px)
		@apply fixed top-[60px] left-0 h-[calc(100dvh-60px)] z-[999] bg-white p-5 flex flex-col gap-5 w-screen overflow-auto translate-x-full
		+transition-base()
		&.isOpen
			@apply translate-x-0
		.wrap-form-group
			@apply flex flex-col gap-5 flex-1 overflow-auto
		.form-submit
			button
				@apply flex-1 h-[45px] #{!important}
		.label-title
			@apply mb-4 text-[16px]
	.label-title
		@apply font-bold text-primary-500 block
	.form-group-select
		@media (max-width: 1023.98px)
			@apply flex-none
			.select-options
				@apply block
				@apply static shadow-none bg-transparent min-w-fit px-0 py-5 #{!important}
		&.full-wide
			@apply static
			.select-options
				@apply w-full py-10 px-10
			.form-group
				@apply relative
				&::after
					content: ''
					@apply absolute top-full w-[110%] h-[1px] bg-neutral-200 left-1/2 -translate-x-1/2 md:opacity-0 opacity-100
				&:nth-child(1),&:nth-child(2),&:nth-child(3)
					&::after
						@apply opacity-100
				&:nth-child(2),&:nth-child(3),&:nth-child(5)
					&::before
						content: ''
						@apply absolute rem:-left-[20px] top-0 h-[calc(100%-20px)] w-[1px] bg-neutral-200
		&.isOpen
			.select-button
				i
					@apply rotate-180
			.select-options
				@apply block
		.select-button
			@apply flex items-center justify-between rem:h-[42px] px-5 border border-neutral-200 rounded-2 text-lg font-medium cursor-pointer whitespace-nowrap gap-4
			i
				@apply text-xl text-neutral-500 font-light transition-all duration-300
		.select-options
			box-shadow: 4px 4px 32px 16px rgba(0, 0, 0, 0.08)
			@apply top-full translate-y-2 left-0 absolute w-full bg-white z-[99] p-5 rounded-2 gap-5 xs:max-lg:min-w-[300px]
			@apply lg:hidden
			.wrap
				+scroll()
				@apply max-h-[10rem] lg:rem:max-h-[200px] overflow-auto pr-5
				@apply flex flex-col gap-5 overflow-y-auto
			.select-options-item
				@apply flex items-center justify-between
				label
					@apply cursor-pointer flex items-center justify-between w-full transition-all duration-300 rem:min-h-[24px]
					@apply hover:text-primary-500
					&::after
						content: '\f0c8'
						@apply font-awesome font-light text-primaryCustom-1
				input[type="checkbox"], input[type="radio"]
					@apply hidden
					&:checked ~ label
						&::after
							content: '\f14a'
							@apply text-primary-500 font-black
	.form-submit
		@apply text-sm font-medium flex
		i
			@apply text-base font-light
		.btn
			@apply flex items-center gap-5 rem:h-[42px] px-2 rem:min-w-[100px] justify-center hover:bg-primary-500 hover:text-white whitespace-nowrap
		.btn-submit,.btn-delete,.btn-close
			@apply flex items-center gap-2 text-primary-500 border border-primary-500 rounded-l-2
		.btn-delete
			@apply rounded-l-none md:rounded-r-2 bg-primary-500 text-white
		.btn-close
			@apply rounded-l-none rounded-r-2 bg-red-500 border-red-500 text-white hover:bg-red-600 hover:border-red-600
	.f-checkbox-group
		@apply pb-8
		.wrap-group-checkbox
			@apply grid grid-cols-2 gap-3 mt-3
		.label-title
			@apply col-span-full w-full
		.f-checkbox
			label
				@apply cursor-pointer flex items-center w-full transition-all duration-300 gap-3 text-[14px] lg:text-sm leading-none
				@apply hover:text-primary-500
				&::before
					content: '\f0c8'
					@apply font-awesome font-light text-[#838383]
			input[type="checkbox"],input[type="radio"]
				@apply hidden
				&:checked ~ label
					&::before
						content: '\f14a'
						@apply text-primary-500 font-black
	.f-slider-range
		@apply py-3 md:py-0
		#slider-acreage
			box-shadow: none
			@apply w-full h-1 bg-neutral-400 border-0 rounded-full overflow-visible
			.noUi-connect
				@apply bg-primary-500
			.noUi-handle
				filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.36))
				box-shadow: none
				@apply w-[14px] h-[14px] bg-white rem:-right-[6px] rounded-full border border-neutral-50 hover:bg-primary-500 transition-all duration-300 border-white  #{!important}
				@apply cursor-pointer
				&::before, &::after
					@apply hidden
		.slider-number
			@apply flex items-center
			&.max
				@apply text-primary-500
			input
				@apply hidden
.loading-filter
	@apply fixed top-0 left-0 w-full h-full bg-black/20 backdrop-blur-sm z-[999]
	.loading-filter-inner
		animation-duration: .35s
		@apply w-[50px] h-[50px] border-[6px] border-primary-500 border-t-white rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ease-linear
		@apply animate-spin
.invalid-feedback
	@apply text-red-500 text-sm pt-2
.form-control
	&.is-invalid
		@apply border-red-500 #{!important}
.form-message
	.alert
		@apply border-4  bg-white rounded-2 overflow-hidden p-2 mt-3 mb-3
	.alert.alert-danger
		@apply border-red-500
		ul
			@apply flex flex-col text-sm text-red-500 gap-1
	.alert.alert-success
		@apply text-green-500 border-green-500
	.alert.alert-info
		@apply text-primary-600 border-primary-600
