@layer components
	.item-comp
		@apply flex
	.pagination-style-primary
		.swiper-pagination-bullet
			@apply bg-primaryCustom-1 opacity-50 transition-all duration-300
		.swiper-pagination-bullet-active
			@apply bg-primary-500 opacity-100
	.tabslet-tab-primary
		@apply flex items-center overflow-auto pb-5 pt-2
		&.base-font-size
			li
				a
					@apply text-base
		&.no-spacing
			@apply gap-10
			li
				a
					@apply px-0
		li
			&.active
				a
					@apply text-black
					&::before, &::after
						@apply opacity-100
			a
				@apply text-lg font-bold text-[#838383] px-5 leading-none py-3 hover:text-black relative whitespace-nowrap flex-center
				&::before
					content: ''
					@apply w-full h-[2px] bg-primary-500 absolute top-full left-0 transition-all duration-300
					@apply opacity-0
				&::after
					content: ''
					@apply w-0 h-0
					@apply border-l-[8px] border-r-[8px] border-b-[10px]
					@apply border-l-transparent border-r-transparent border-b-primary-500
					@apply absolute top-full left-1/2 -translate-x-1/2 rotate-180
					@apply opacity-0 transition-all duration-300
	.item-product
		@apply flex flex-col h-full
		&:hover
			.product-title
				@apply text-primary-500
			.product-image
				img
					@apply scale-105
		.tags-list
			@apply absolute top-2.5 right-2.5 z-2
			@apply flex items-center gap-2
			li
				@apply text-sm font-medium bg-primary-500
				@apply leading-none text-white px-2 rounded-1 rem:min-h-[28px] flex-center
				&.sale
					@apply bg-primary-950
				&.hot
					@apply bg-button-primary
		.product-title
			@apply transition-all duration-300
		.product-image
			@apply rem:rounded-t-[12px]
		.product-content
			@apply border border-[#BDBDBD] border-t-0 rem:rounded-b-[12px] flex-1
		&.primary
			.product-specs
				@apply gap-7
				i
					@apply text-base
		&.big
			.btn-video
				@apply w-20 h-20
				&::before
					@apply text-3xl
		&.min
			.product-content
				@apply p-4 gap-2
			.product-location,.product-price
				@apply text-sm
				i
					@apply text-xs
		.product-specs
			@apply mt-auto
			i
				@apply text-[#7A848F]
		&:hover
			.btn-video
				box-shadow: 0 0 0 11.5px rgb(0 144 212 / 25%), 0 0 0 20px rgb(0 144 212 / 20%)
	.btn-video
		box-shadow: 0 0 0 0px rgb(0 144 212 / 25%), 0 0 0 0px rgb(0 144 212 / 20%)
		@apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2
		@apply w-15 h-15 bg-primary-500 flex-center rounded-full text-white
		@apply transition-all duration-300
		&::before
			content: '\f04b'
			@apply font-awesome text-2xl font-black  rem:translate-y-[1px]  rem:translate-x-[3px]
	.item-product-secondary
		@apply relative
		&::after
			content: ''
			background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.60) 50%, rgba(255, 255, 255, 0.00) 100%)
			@apply absolute right-0 top-0 w-[2px] h-full translate-x-[1px] z-2 pointer-events-none
		&::before
			content: ''
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.80) 100%)
			opacity: 0.5
			@apply absolute left-0 bottom-0 w-full h-full z-1 pointer-events-none transition-all duration-300
		&:hover
			&::before
				opacity: 1
				@apply h-[100%]
			.product-info
				@apply translate-y-0 pointer-events-auto
			.btn-primary
				@apply opacity-100
		.btn-primary
			@apply opacity-0 transition-all duration-300
		.product-info
			@apply translate-y-[3.6458333333rem] lg:translate-y-[4.6875rem] transition-all duration-300 pointer-events-none
		.product-image
			img
				@apply transition-all duration-300
	.item-news
		.news-title
			@apply text-lg font-bold py-3 border-b border-neutral-300 mb-3
			a
				@apply line-clamp-2 hover:text-primary-500 transition-all duration-300
		.news-info
			@apply flex flex-col
			.news-content
				@apply line-clamp-2 text-sm
		.news-image
			.img-ratio
				@apply ratio:pt-[160_240]
		&.horizontal
			@apply grid grid-cols-[1fr_150px] md:grid-cols-[1fr_12.5rem] gap-5
			&.not-reverse
				@apply grid-cols-[9.4791666667rem_1fr]
				.news-info
					@apply order-2
			.news-title
				@apply flex-1 mb-0 md:mb-2 xs:max-md:border-b-0 xs:max-md:py-3 #{!important}
			.news-image
				.img-ratio
					@apply mb-0
			.news-info
				@apply -order-1
				@media (max-width: 767.98px)
					// .news-tag,.dot
					// 	@apply hidden
					.news-content
						@apply hidden
		&.big
			.news-image
				.img-ratio
					@apply ratio:pt-[408_680] mb-3
			.news-title
				@apply text-2xl mb-4
			.news-content
				@apply line-clamp-3
	.pagination-primary
		@apply mt-10
		ul
			@apply flex items-center justify-center gap-3
			li
				&.active
					a
						@apply bg-primary-500 text-white border-primary-500
				a
					@apply text-neutral-500 border border-neutral-500 flex-center w-10 h-10 rounded-2
					@apply hover:bg-primary-500 hover:text-white hover:border-primary-500
	.fancybox__slide
		@apply py-12
	.tabslet-tab-secondary
		@apply flex xl:gap-20 lg:gap-10 gap-5 border-b border-neutral-200 overflow-auto
		li
			&.active
				a
					@apply text-primary-500
			a
				@apply text-lg font-bold py-3 lg:py-2 block whitespace-nowrap
	.table-style-primary
		table
			tr
				@apply border-b border-neutral-200 first:border-t
				td
					@apply py-4
					@apply text-primaryCustom-2 last:font-bold last:text-primaryCustom-1 last:text-right
	.pagination-style-secondary
		@apply flex-center mt-4 static
		.swiper-pagination-bullet
			@apply bg-primaryCustom-1 opacity-50 transition-all duration-300
			@apply w-3 h-3 rounded-full flex-shrink-0
		.swiper-pagination-bullet-active
			@apply bg-primary-500 opacity-100
	.popup-style-primary
		.carousel__button.is-close
			top: rr(15px)
			right: rr(15px)
			@media (max-width: 767.98px)
				top: 0
				right: 0
				transform: translate(25%, -30%)
				@apply bg-primary-500 #{!important}
			path
				@apply stroke-white md:stroke-black rem:stroke-[1px]
	.item-news-featured
		.img
			&::before
				content: ''
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 22.5%, rgba(0, 0, 0, 0.70) 90.5%)
				@apply absolute top-0 left-0 right-0 bottom-0 z-2 pointer-events-none
	.input-icon
		background-size: 0.9375rem 1.0416666667rem
		background-position: center right 0.38125rem
		background-repeat: no-repeat
		background-image: url(../img/icon/user-white.svg)
		&.email
			background-image: url(../img/icon/envelope-white.svg)
		&.phone
			background-image: url(../img/icon/phone-white.svg)
	.input-style-primary
		input
			@apply py-4 px-2 border-b border-white text-white text-lg pr-10 bg-transparent w-full placeholder:text-white
			@apply outline-none hocus:border-primary-600 transition-all duration-300
	.wrap-tools-social
		li
			&:hover
				img
					@apply filter-white
	.table-compare-style
		@apply border border-neutral-200 rounded-3 w-fit min-w-full
		table
			@apply border-collapse rounded-3 overflow-hidden border border-neutral-200
			td,th
				@apply px-5 py-3
			thead
				@apply rounded-t-3 overflow-hidden
				tr
					th
						@apply bg-primary-500 text-white text-center border-r border-neutral-200 whitespace-nowrap border
						&:first-child
							@apply text-left rounded-tl-3
						&:last-child
							@apply text-left rounded-tr-3 border-r-0 overflow-hidden
			tbody
				tr
					&:nth-child(odd)
						td
							@apply bg-white
					&:nth-child(even)
						td
							@apply bg-neutral-50
					&:last-child
						td
							@apply border-b-0
					td
						&[class*="bg-primary-500"]
							@apply bg-primary-500 text-white
						@apply border-b border-r border-neutral-200 text-center
						.fa-circle-check
							@apply text-[#10c900] text-xl font-black
						.fa-circle-xmark
							@apply text-xl text-[#B0BDCB]
						&:first-child
							@apply text-left
body.focus-form
	&::before
		content: ''
		@apply fixed top-0 left-0 right-0 bottom-0 bg-black/30 backdrop-blur-sm z-50
	#form-contact
		@apply z-[100]
